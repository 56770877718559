import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "person-and-pronouns"
    }}>{`Person and Pronouns`}</h1>
    <p>{`Use these guidelines and tricks to choose the correct pronoun when referring to people or things.`}</p>
    <p><strong parentName="p">{`Referring to Workday`}</strong></p>
    <p>{`When referring to Workday the company, use the first-person plural pronouns we, us, our, and ours.`}</p>
    <p><strong parentName="p">{`Referring to Customers`}</strong></p>
    <p>{`When referring to customers, members, consumers, subscribers, workers, or anyone else reading your
text, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In general, use the second person (you, your, and yours).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Although this guide employs the word “users” to refer to anyone accessing or using Workday, the
word “user” can sound too techie or cold. Depending on context, consider using employee, consumer,
reader, subscriber, customer, worker, participant, visitor, or similar words.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When you need to use a third person pronoun, avoid gendered pronouns and refer to the user as
"they" rather than as "he" or "she." Using "they" as a singular, third-person pronoun allows us to
address diversity by avoiding gendered language and encompassing many variations of gender
self-identification.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`My vs. Your`}</strong></p>
    <p>{`“My” is typically applied to page names/titles.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`My Time Off`}</li>
      </ul>
    </Do>
    <p>{`“Your” is best used in instructional and error message text.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Enter your name in the text box.`}</li>
      </ul>
    </Do>
    <p>{`See also: `}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/error-alert-messages"
      }}>{`Error and Alert Messages`}</a>{`,
`}<a parentName="p" {...{
        "href": "/guidelines/content/ui-text/instructional-text"
      }}>{`Instructional Text`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      